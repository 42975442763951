<template>
  <div class="details">
    <div class="projectInfo marginBottom">
      <img :src="orderInfo.serveCoverImg" alt="" class="projectImg" />
      <div class="info">
        <p class="name">{{ orderInfo.serveName }}</p>
        <p class="price-P">
          <span class="price">￥{{ orderInfo.price }}</span>
          <span class="num">×{{ orderInfo.num }}</span>
        </p>
        <p class="time-p">
          <van-icon name="clock-o" class="timeIcon" />
          <span>{{ orderInfo.serveDuration }}分钟</span>
        </p>
        <p class="introduce van-ellipsis">{{ orderInfo.serveIntroduction }}</p>
      </div>
    </div>
    <div class="reservation-div marginBottom" v-if="orderInfo.refundStatus == 1">
      <p class="flex-p" style="color: #d60303;font-size: 20px;font-weight: bold;margin-top: 0; ">{{orderInfo.refundAmount}}</p>
      <p class="flex-p">已退款（元）</p>
    </div>
    <div class="reservation-div marginBottom">
      <p class="title">预约信息</p>
      <p class="flex-p">
        <span>联系人</span><span>{{ orderInfo.appointmentPersonName }}</span>
      </p>
      <p class="flex-p">
        <span>联系电话</span><span>{{ orderInfo.appointmentPhone }}</span>
      </p>
      <p class="flex-p">
        <span>预约时间</span><span>{{ orderInfo.appointmentTime }}</span>
      </p>
      <p class="flex-p">
        <span>服务地址</span><span>{{ orderInfo.appointmentAddress }}</span>
        <img src="../../assets/img/copy.png" alt="点击复制"  class="copyImg"   v-clipboard:copy="orderInfo.appointmentAddress" v-clipboard:success="CopySuccess" v-clipboard:error="CopyError"/>
      </p>
    </div>
    <div class="reservation-div marginBottom">
      <p class="title">服务信息</p>
      <p class="flex-p">
        <span>服务总金额</span><span>￥{{ orderInfo.price }}</span>
      </p>
      <p class="flex-p">
        <span>技师姓名</span>
        <span class="telIconSpan">
          {{ orderInfo.artificerName }}
          <!-- {{ orderInfo.nickName }} -->
        </span>
      </p>
      <p class="flex-p" v-if="orderInfo.status == 1 && (orderInfo.artificerStatus == 1 || orderInfo.artificerStatus == 2 || orderInfo.artificerStatus == 3 || orderInfo.artificerStatus == 4)">
        <span>联系技师</span>
        <span>
          <svg-icon
            icon-class="tel"
            class="telIcon"
            @click="clickTelArtificer()"/>
        </span>
      </p>
      <p class="flex-p" v-if="orderInfo.discountVipAmount">
        <span>会员减免</span><span>-￥{{ orderInfo.discountVipAmount }}</span>
      </p>
      <p class="flex-p" v-if="orderInfo.discountCouponAmount">
        <span>优惠券减免</span
        ><span>-￥{{ orderInfo.discountCouponAmount }}</span>
      </p>
      <p class="flex-p van-hairline--bottom" v-if="orderInfo.buyVipAmount">
        <span>会员购买</span><span>￥{{ orderInfo.buyVipAmount }}</span>
      </p>
      <p class="flex-p" v-if="orderInfo.trafficWay !=3">
        <span>出行方式</span
        ><span>{{
          { 0: "公交/地铁", 1: "网约车" }[orderInfo.trafficWay]
        }}</span>
      </p>
      <p class="flex-p van-hairline--bottom" v-if="orderInfo.trafficAmount">
        <span>出行费用</span><span>￥{{ orderInfo.trafficAmount }}</span>
      </p>
      <p class="flex-p van-hairline--bottom" v-if="orderInfo.clockUpAmount">
        <span>加钟费用</span
        ><span>￥{{ orderInfo.clockUpAmount }}<van-icon name="arrow"/></span>
      </p>
      <p class="flex-p">
        <span>订单总金额</span><span>￥{{ orderInfo.orderAmount }}</span>
      </p>
      <p class="flex-p">
        <span>支付金额</span>
        <span class="playPrice">￥{{ orderInfo.actualPaymentAmount }}</span>
      </p>
    </div>
    <div class="reservation-div marginBottom" v-if="!$route.query.from">
      <p class="title">订单信息</p>
      <div class="flex-p">
        <span>订单号</span>
        <div style="display: flex;align-items: center;">
          <span>{{ orderInfo.orderNo }}</span>
          <img  src="../../assets/img/copy.png" alt="点击复制"  class="copyImg"   v-clipboard:copy="orderInfo.orderNo" v-clipboard:success="CopySuccess" v-clipboard:error="CopyError"/>
        </div>
      </div>
      <p class="flex-p" v-if="orderInfo.payStatus == 1">
        <span>支付方式</span><span>微信支付</span>
      </p>
      <p class="flex-p">
        <span>下单时间</span><span>{{ orderInfo.orderTime }}</span>
      </p>
    </div>
    <div class="reservation-div marginBottom" v-if="orderInfo.remark">
      <p class="title">备注</p>
      <p class="remarks">{{ orderInfo.remark }}</p>
    </div>
    <div class="operate-div2" v-if="orderInfo.payStatus == 0 && orderInfo.status == 0">
      <span
        >支付费用 <font> ￥{{ orderInfo.actualPaymentAmount }}元</font></span
      >
      <span class="operateBtn" @click="confirmOrderPlay">立即支付</span>
    </div>
    <div class="operate-div" v-else>
      <!-- <span class="operateBtn" @click="orderOperate(1)">再来一单</span> -->
      <span
        class="operateBtn"
        @click="callTelShow = true"
        v-if="
          orderInfo.status == 3 &&
          orderInfo.afterStatus == 1
        ">申请售后</span>
      <span 
        class="operateBtn" 
        @click="orderOperate(2)"
        v-if="
          orderInfo.status == 3 && 
          orderInfo.commentStatus == 1
        ">去评价</span>
      <span
        class="operateBtn"
        @click="orderOperate(3)">删除订单</span>
      <span
        class="operateBtn"
        @click="pushWxkf"
        v-if="
          orderInfo.status != 3 &&
          orderInfo.status != 2 &&
          orderInfo.payStatus == 1
        ">取消订单</span>
        <!-- <span
          class="operateBtn"
          @click.stop="orderOperate(5)"
          v-if="
            orderInfo.status == 1 
            && orderInfo.artificerStatus == 4
          ">服务加钟</span> -->
    </div>
    <callServiceTel
      :callTelShow="callTelShow"
      v-if="callTelShow"
      @telHide="callTelShow = false"
    />
    <!-- 支付loading -->
    <van-popup
      v-model="startPaymentShow"
      :close-on-click-overlay="false"
      round
      :overlay-style="{ background: 'transparent' }"
      :style="{ padding: '20px', background: '#1c1515c7' }"
    >
      <van-loading size="40px" vertical style="margin-bottom: 30px;" />
      <span style="font-weight: bold;color: white;">正在查询支付结果...</span>
    </van-popup>
  </div>
</template>

<script>
import {
  getLsOrderDetailOut,
  jsPayOrder,
  getClockOrderDetailOut,
  delOrder,virtualNumber
} from "@/api/order";
import callServiceTel from "@/components/callServiceTel";
import { getLsServeDetailOut } from "@/api/index";
import { wxConfig } from "../../utils/index";
import { Dialog } from "vant";
import wx from "weixin-js-sdk";

export default {
  components: { callServiceTel },
  data() {
    return {
      callTelShow: false,
      orderInfo: "",
      startPaymentShow: false,
      isClickPlay:false,
      totalErrorPayOrder:0,   // 统计调用支付订单失败次数
    };
  },
  created() {
    if (this.$route.query.from == "clock") {
      this.getLsServeDetailOut();
    } else {
      this.getLsOrderDetailOut();
    }
  },
  methods: {
    CopySuccess(e) {
      this.$toast('复制成功')
    },
    CopyError(e) {
      this.$toast('复制失败')
    },
    pushWxkf(){
      window.location.href = "https://work.weixin.qq.com/kfid/kfc17cbbbde9caf850f"
    },
    // 点击支付
    confirmOrderPlay() {
      if (this.$route.query.from == "clock") {
      } else {
        jsPayOrder({ orderId: this.$route.query.order_id }).then(res => {
          if (res.data) {
            this.wxPlay(res);
          }
        });
      }
    },
    // 微信支付
    wxPlay(res) {
      let _this = this;
      wx.chooseWXPay({
        appId: res.data.appId,
        timestamp: res.data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: res.data.nonceStr, // 支付签名随机串，不长于 32 位
        package: res.data.prepayId, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: "RSA", // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign: res.data.paySign, // 支付签名
        success: function(payRes) {
          _this.startPaymentShow = true;
          _this.isClickPlay = true
          if(_this.$route.query.from == "clock"){

          }else{
            _this.getLsOrderDetailOut()
          }
        },
        cancel: function(payRes) {
          _this.$toast.fail("取消支付");
        },
        fail: function(payRes) {
          _this.$toast.fail("支付失败");
        }
      });
    },
    // 订单操作 1 再来 2 评价 3 删除  5 加钟
    orderOperate(type) {
      if (type == 1) {
        this.$router.push({
          path: "/order/confirmOrder",
          query: {}
        });
      } else if (type == 2) {
        this.$router.push({
          path: "/order/evaluate",
          query: {}
        });
      } else if (type == 3) {
        delOrder({ orderId: this.$route.query.order_id }).then(res => {
          if (res.data) {
            this.$toast("删除成功");
            this.$router.go(-1);
          }
        });
      } else if (type == 5) {
        this.$router.push({
          path: "/order/orderClock",
          query: {
            order_id: this.orderInfo.orderId
          }
        });
      }
    },
    // 点击拨打技师电话
    clickTelArtificer() {
      virtualNumber(this.$route.query.order_id).then(res=>{
        Dialog.alert({
          title: "确认呼叫技师",
          className: "dialogClassName",
          width: "260",
          message:
            this.orderInfo.artificerName + " " + res.data
            // this.orderInfo.nickName + " " + res.data
        }).then(() => {
          window.location.href = "tel:" + res.data;
          // on close
        });
      })
      
    },
    // 获取普通订单详情
    getLsOrderDetailOut() {
      // 防止一直回调失败导致系统卡死问题
      if(this.totalErrorPayOrder > 6){
        this.startPaymentShow = false;
        return;
      }
      getLsOrderDetailOut({ orderId: this.$route.query.order_id }).then(res => {
        this.orderInfo = res.data;
        let _this = this;
        let orderPlayInterval;
        if(res.data.payStatus == 1 && this.isClickPlay){
          this.startPaymentShow = false;
          clearInterval(orderPlayInterval)
        }else if(res.data.payStatus == 0 && this.isClickPlay){
          orderPlayInterval = setInterval(() => {
            this.totalErrorPayOrder++;
            _this.getLsOrderDetailOut()
          }, 2000);
        }
      });
    },
    // 加钟订单 获取项目信息
    getLsServeDetailOut() {

      const cityCode = localStorage.getItem("cityCode") || "110100"

      getLsServeDetailOut({ serveId: this.$route.query.project_id, cityCode: cityCode}).then(
        res => {
          let newObj = {};
          newObj.serveCoverImg = res.data.serveCoverImg;
          newObj.serveName = res.data.serveName;
          newObj.serveDuration = res.data.serveTime;
          newObj.price = res.data.servePrice;
          newObj.orderAmount = res.data.servePrice;
          newObj.actualPaymentAmount = res.data.servePrice;
          newObj.num = 1;
          newObj.serveIntroduction = res.data.serveIntroduce;
          getClockOrderDetailOut({ orderId: this.$route.query.order_id }).then(
            order => {
              newObj.appointmentPersonName = order.data.appointmentPersonName;
              newObj.appointmentAddress = order.data.appointmentAddress;
              newObj.appointmentPhone = order.data.appointmentPhone;
              newObj.appointmentTime = order.data.appointmentTime;
              newObj.artificerName = order.data.artificerName;
              // newObj.nickName = order.data.nickName;
              this.orderInfo = newObj;
            }
          );
        }
      );
    }
  }
};
</script>

<style lang="less" scoped>

.copyImg{
  width: 0.3rem;
  height: 0.3rem;
}

.details {
  min-height: 100vh;
  background: #f1f1f1;
  padding: 0.4rem 0.4rem 1.4rem;
  box-sizing: border-box;
  .title {
    font-size: 0.4rem;
    font-weight: bold;
  }
  .marginBottom {
    margin-bottom: 0.3rem;
  }
  .van-hairline--bottom {
    padding-bottom: 0.3rem;
  }
  .flex-p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.3rem 0;
    &:last-child {
      margin-bottom: 0;
    }
    span:first-child {
      color: gray;
      white-space: nowrap;
      margin-right: 0.6rem;
    }
    .playPrice {
      font-size: 20px;
      color: #d60303;
    }
  }
  .projectInfo,
  .reservation-div {
    background: white;
    padding: 0.4rem;
    border-radius: 0.2rem;
  }
  .telIconSpan {
    display: flex;
    align-items: center;
    .icon{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-right: 0.2rem;
      
      .telText{
        font-size: 0.2rem;
      }
    }
  }
  .telIcon {
      font-size: 0.6rem;
    }
  .projectInfo {
    display: flex;
    .projectImg {
      width: 2rem;
      height: 2rem;
      border-radius: 0.2rem;
      margin-right: 0.4rem;
    }
    .name {
      font-size: 0.4rem;
      font-weight: bold;
    }
    .price-P {
      display: flex;
      justify-content: space-between;
      .price {
        color: orange;
        font-weight: bold;
      }
    }
    .time-p {
      color: gray;
      .timeIcon {
        margin-right: 0.2rem;
      }
    }
    .introduce {
      font-size: 0.28rem;
      color: gray;
    }
    .info {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .remarks {
    margin-top: 0.3rem;
  }
  .operate-div,
  .operate-div2 {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: white;
    display: flex;
  }
  .operate-div {
    padding: 0.3rem;
    box-sizing: border-box;
    justify-content: flex-end;
    .operateBtn {
      margin-left: 0.3rem;
      width: 2rem;
      height: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.36rem;
      border: solid 1px #e3e3e3;
      border-radius: 0.6rem;
    }
  }
  .operate-div2 {
    span:first-child {
      flex: 1;
      padding-left: 0.4rem;
      font-size: 0.4rem;
      display: flex;
      align-items: center;
      font {
        font-size: 0.5rem;
        color: rgb(214, 3, 3);
      }
    }
    .operateBtn {
      width: 3.5rem;
      height: 1.5rem;
      font-weight: bold;
      font-size: 0.5rem;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgb(214, 3, 3);
    }
  }
}
</style>
